import React from "react";
import { Alert, AlertProps } from "@mui/material";

export type MhcAlertProps = Omit<AlertProps, "variant">;

export const MhcAlert: React.FC<MhcAlertProps> = ({ ...props }) => {
  return (
    <Alert variant="standard" {...props}>
      {props.children}
    </Alert>
  );
};
